//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-216:_4192,_1048,_8568,_2928,_4312,_7608,_2784,_8232;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-216')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-216', "_4192,_1048,_8568,_2928,_4312,_7608,_2784,_8232");
        }
      }catch (ex) {
        console.error(ex);
      }